import React, { useEffect } from 'react';
import "./elfsight-widget.style.scss";
const ElfsightWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.async = true;
        document.body.appendChild(script);
        const hideElfsightLink = () => {
            const link = document.querySelector('.elfsight-app-a02825ba-368a-4a10-b1c1-3852f64472a3 > a[title]');
            if (link) {
                link.style.display = 'none';
            }
        };

        script.onload = () => {
            setTimeout(hideElfsightLink, 2000);
        };
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            className="elfsight-app-a02825ba-368a-4a10-b1c1-3852f64472a3"
            data-elfsight-app-lazy
        ></div>
    );
};

export default ElfsightWidget;
