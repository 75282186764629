import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Header } from "../components/header/header.view";
import { Footer } from "../components/footer/footer.view";
import { Section } from "../components/section/section.view";
import { Video } from "../components/video/video.view";
import ElfsightWidget from "../components/elfsight-widget/elfsight-widget.view";
//import { Banner } from "../components/banner/banner.view";

const Landing = ()=>{
    useEffect(() => {
        AOS.init({
            easing: 'linear',
            once: true
        });
        AOS.refresh();
    }, []);
    return (
        <main className={"main"}>
            <Header/>
            <Video id="1"/>
            {/*<Banner id="primary"/>*/}
            <ElfsightWidget />
            <Section targetId="was"/>
            <Section targetId="woWann"/>
            <Section targetId="shop"/>
            <Section targetId="warum"/>
            <Section targetId="wie"/>
            <Section targetId="wie-2"/>
            <Section targetId="wie-3"/>
            <Section targetId="wie-4"/>
            <Section targetId="mitmachen"/>
            <Section targetId="muur"/>
            <Section targetId="wer"/>
            <Video id="0"/>
            <Footer/>
        </main>
    )
}
export default Landing;
